export default [
  {
    label: 'Follower Count',
    code: [
      'variants.attributes.dspn asc.max',
      'variants.attributes.follower_count_numeric desc',
    ],
    default: true,
  },
  {
    label: 'Best Match',
    code: ['variants.attributes.dspn asc.max', 'score desc'],
    default: false,
  },
  {
    label: 'Creator Rating',
    code: [
      'variants.attributes.dspn asc.max',
      'reviewRatingStatistics.averageRating desc',
    ],
    default: true,
  },
  {
    label: 'Price Low to High',
    code: ['variants.attributes.dspn asc.max', 'price asc'],
    default: true,
  },
  {
    label: 'Price High to Low',
    code: [
      'variants.attributes.dspn asc.max',
      'variants.attributes.price_from.centAmount desc',
    ],
    default: true,
  },
]
