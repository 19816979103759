import type { ProductsRequestQuery } from 'gig/types/products-request-query'
import type { ProductGridItem } from 'gig/types/product-grid-item'
import type { Creator } from 'profile/types/profile'
import { categoryFilters } from '@/data/category/category-filters'
import sortMethods from '@/data/category/sort-methods'
import type { Product, Products } from '@/types/products'
import type { RequestParams } from '@/types/request-params'
import type { CreatorObject } from '@/types/variant'

export default async function (options?: {
  limit?: number
  ignoreParamsFromUrl?: boolean
  sortMethod?: string | string[]
}) {
  const activeFilters = ref<ProductsRequestQuery[]>([])
  const limit = ref(options?.limit || 12)
  const offset = ref(0)
  const sortMethod = ref(options?.sortMethod || sortMethods[0].code)
  const products = ref<ProductGridItem[]>([])
  const total = ref(0)
  const lang = 'en-US'
  const { $price } = useNuxtApp()
  const acceptableQueryFilters = categoryFilters.map((item) => item.id)
  const router = useRouter()
  const route = useRoute()
  const runtimeConfig = useRuntimeConfig()

  const getFormattedFilters = (queries: ProductsRequestQuery[]): string[] => {
    if (!queries.length) {
      return []
    }

    return queries.map((currentItem) => {
      const value = currentItem.value?.value.map((item) =>
        [
          'variants.price.centAmount:range',
          'variants.attributes.follower_count_numeric',
          'reviewRatingStatistics.averageRating',
        ].includes(currentItem.value.id)
          ? item
          : `"${item}"`,
      )
      return `${currentItem.value.id}${
        currentItem.value.id === 'variants.price.centAmount:range' ? '' : ':'
      }${value.join(',')}`
    })
  }

  const getFormattedItem = (
    item: Product,
    page?: ProductGridItem['page'],
  ): ProductGridItem => {
    const assets = useAssets(item.masterVariant)

    const creatorObject = item.masterVariant.attributes.find(
      (attr) => attr.name === 'creator',
    )?.value as CreatorObject | undefined
    let creator: Creator | null = null
    if (creatorObject?.obj) {
      creator = useCreator({
        ...creatorObject.obj.masterData.current,
        id: creatorObject.obj.id,
        createdAt: creatorObject.obj.createdAt,
      })
    }

    return {
      id: item.id,
      image: assets[0]?.image || assets[0]?.thumbnail,
      imageAlt: assets[0]?.name?.['en-US'],
      avatar: creator?.owner?.id
        ? `${runtimeConfig.public.uploadUrl}profile/${creator?.owner?.id}`
        : undefined,
      creatorName: creator?.name || '',
      rating: item?.reviewRatingStatistics?.averageRating,
      name: item.name?.[lang],
      price: item?.masterVariant.prices?.[0].value
        ? $price(item.masterVariant.prices[0].value)
        : '',
      link: `/collab/${item.slug[lang]}`,
      page,
      socials: creator?.socials || [],
    }
  }

  const getRequestParams = (): RequestParams => {
    const filters = getFormattedFilters(activeFilters.value)
    const isFullTextSearch =
      route.params.query === 'all' ||
      !route.params?.query ||
      options?.ignoreParamsFromUrl

    return {
      expand: 'masterVariant.attributes[*].value',
      limit: limit.value,
      offset: offset.value,
      filter: filters,
      sort: sortMethod.value,
      'text.en-US': isFullTextSearch
        ? undefined
        : (route.params.query as string),
    }
  }

  const setUrlQuery = () => {
    let urlQuery: any = {}
    if (Object.keys(activeFilters.value).length !== 0) {
      urlQuery = activeFilters.value.reduce((accumulator, item) => {
        const key = item.value.id.replace('variants.attributes.', '')
        return { ...accumulator, [key]: item.value.value.join(',') }
      }, {})
    }

    if (offset.value >= limit.value) {
      urlQuery.page = offset.value / limit.value + 1
    }

    if (sortMethod.value) {
      urlQuery.sort = sortMethod.value
    }

    router.push({
      query: urlQuery,
    })
  }

  const doFetchProducts = async (
    { replaceCurrentProducts } = { replaceCurrentProducts: true },
  ) => {
    const listingStore = useListingStore()
    try {
      listingStore.isProcessing = true
      const { data, error } = await useApi<Products>('opensearch', {
        params: getRequestParams(),
      })

      if (error.value) {
        throw new Error(error.value.message)
      }

      if (data.value) {
        if (data.value.offset > data.value.total) {
          throw new Error('No search data found.')
        }
        const pageIndex = offset.value / limit.value + 1
        const page = pageIndex > 1 ? String(pageIndex) : undefined
        const fetchedProducts = data.value.results?.map((item: any) =>
          getFormattedItem(item, page),
        )
        if (replaceCurrentProducts) {
          products.value = fetchedProducts
        } else {
          products.value = [...products.value, ...fetchedProducts]
        }
        total.value = data.value.total || 0
      }
    } catch (error) {
      throw createError({
        statusCode: 404,
        statusMessage: 'No search data found.',
        fatal: true,
      })
    } finally {
      listingStore.isProcessing = false
    }
  }

  const setParamsFromUrlQuery = () => {
    if (
      !options?.ignoreParamsFromUrl &&
      Object.keys(route.query).length !== 0
    ) {
      Object.entries(route.query).forEach(([key, value]: any) => {
        if (key === 'page') {
          offset.value = value * limit.value - limit.value
        } else if (key === 'sort') {
          sortMethod.value = value
        } else if (
          key === 'variants.price.centAmount:range' ||
          key === 'reviewRatingStatistics.averageRating'
        ) {
          activeFilters.value.push({
            name: 'filter',
            value: {
              id: key,
              value: [value],
            },
          })
        } else if (
          acceptableQueryFilters.includes(`variants.attributes.${key}`)
        ) {
          activeFilters.value.push({
            name: 'filter',
            value: {
              id: `variants.attributes.${key}`,
              value: value.split(','),
            },
          })
        }
      })
    }
  }

  setParamsFromUrlQuery()
  await doFetchProducts()

  const queryParam = ref(route.params.query)

  watch([activeFilters, sortMethod, queryParam], () => {
    setUrlQuery()
    doFetchProducts()
  })

  watch(offset, (newVal, oldVal) => {
    setUrlQuery()
    doFetchProducts({ replaceCurrentProducts: newVal < oldVal })
  })

  return {
    products,
    total,
    limit,
    offset,
    sortMethod,
    activeFilters,
    setParamsFromUrlQuery,
  }
}
